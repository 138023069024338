<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 60 60" height="60" width="60">
    <g>
      <g id="Capa_1">
        <g>
          <path class="cls-1" d="M28.5,22.4v-.9s0,0,0,0c-1,1.1-2.5,1.5-3.9,1-1.1-.4-1.9-1.2-2.2-2.3h4.5c0,0,.1,0,.2,0,0,0,0,0,0,0,0-.2.2-.4.3-.7h-5.2v-.7h5.2c0,0,.2,0,.3,0,0,0,0-.2.1-.2,0-.2.1-.3.2-.5h-5.7c.3-1,1.1-1.9,2.1-2.2,1.4-.6,3.1-.2,4.1,1,0-.2.2-.4.3-.7,0,0,0,0,0,0-.6-.6-1.4-1-2.2-1.2-.2,0-.3,0-.5,0h0c-.2,0-.4,0-.6,0-.2,0-.4,0-.6,0-.9.2-1.7.6-2.3,1.3-.5.5-.9,1.2-1,1.9h-.3c-.2,0-.4,0-.6,0,0,0-.1,0-.1,0,0,.2-.2.4-.3.6h0s1.1,0,1.1,0v.7h-.5c0,0-.2,0-.3,0s0,.2-.1.3c0,.1-.1.3-.2.4h0s1.2,0,1.2,0c0,.4.2.7.4,1,.6,1.2,1.8,1.9,3.1,2.1.1,0,.3,0,.4,0h.8c.8-.1,1.5-.4,2.2-.9,0,0,0,0,0-.1Z"/>
          <path class="cls-1" d="M27.1,33.4h-1.3c-.2,0-.3.2-.3.3s.2.3.3.3h1.3c.2,0,.3-.2.3-.3s-.2-.3-.3-.3Z"/>
          <path class="cls-1" d="M49.8,40c-1.4,0-2.6,1.1-2.7,2.6,0,1.4,1.1,2.6,2.6,2.7h.1c1.4,0,2.5-1.2,2.6-2.6,0-1.4-1.1-2.6-2.6-2.7ZM49.8,44.6c-1,0-1.9-.8-1.9-1.8s.8-1.9,1.8-1.9h0c1,0,1.9.8,1.9,1.8,0,1-.8,1.9-1.8,1.9Z"/>
          <path class="cls-1" d="M11.9,40c-1.4,0-2.6,1.1-2.7,2.6s1.1,2.6,2.6,2.7h.1c1.4,0,2.5-1.2,2.6-2.6,0-1.4-1.1-2.6-2.6-2.7ZM11.9,44.6c-1,0-1.8-.8-1.8-1.9s.8-1.8,1.8-1.8h0c1,0,1.8.8,1.8,1.9,0,1-.8,1.8-1.9,1.8Z"/>
          <path fill="#252525" stroke="#252525" stroke-width="0.9px" d="M59.9,36.2c-.6-3.8-8.1-6-14.8-6h-.4c-3.6-2.8-7.8-4.6-12.2-5.5,2.4-3.2,2.3-7.6-.2-10.7-3-3.7-8.5-4.2-12.1-1.2-3.7,3-4.2,8.4-1.2,12.1-3.9.8-8.1,2.2-12.4,4.2l-4.7.8c-1.1.2-1.9,1.1-1.9,2.3v6.8c0,.5.2,1.1.6,1.5l1.6,1.8c.4.5,1.1.8,1.7.8h2v.5c.6,2.8,3.1,4.9,6,4.9h0c2.9,0,5.4-2.1,5.9-4.9v-.5h25.9v.5c.6,2.4,2.5,4.3,4.9,4.8.4,0,.7.1,1.1.1,2.9,0,5.4-2.1,5.9-4.9v-.5h1.9c1.3,0,2.3-1,2.3-2.3v-4.7h0ZM19.5,24.6c-2.6-3.1-2.3-7.7.5-10.6,3.1-3.1,8.1-3,11.1,0,2.8,2.8,3.1,7.1.7,10.3l-.2.3c-1.6-.3-3.2-.4-4.8-.4h-.2c-2.2,0-4.5.2-6.9.7l-.3-.3ZM18.9,25.7c2.6-.6,5.2-.8,7.8-.9h.2c5.3,0,10.4,1.4,14.9,4.1l2,1.2h-23.5l-2.2-4.3.8-.2ZM11.9,48c-2.9,0-5.3-2.4-5.3-5.3s2.4-5.3,5.3-5.3h0c2.9,0,5.3,2.4,5.3,5.3,0,2.9-2.4,5.3-5.3,5.3ZM49.8,48c-2.9,0-5.3-2.4-5.3-5.3,0-2.9,2.4-5.3,5.3-5.3h0c2.9,0,5.3,2.4,5.3,5.3,0,2.9-2.4,5.3-5.3,5.3ZM59.2,41c0,.9-.7,1.6-1.6,1.6h-1.8v-.6c-.4-3-2.9-5.2-5.8-5.3h-.2s0,0,0,0c-3.1,0-5.6,2.3-6,5.3v.6h-25.8v-.6c-.4-2.8-2.6-5-5.3-5.3-.2,0-.5,0-.7,0-3.1,0-5.6,2.3-6,5.3v.6h-2.1c-.5,0-.9-.2-1.2-.5l-1.6-1.8c-.2-.3-.4-.7-.4-1v-5.1h1.8c.2,0,.3-.2.3-.4,0-.2-.2-.3-.4-.3H.7v-1.1c0-.8.6-1.4,1.3-1.6l4.7-.8h0c3.2-1.6,6.6-2.8,10.1-3.8h.5c0-.1,2.4,4.5,2.4,4.5l3.9,7.9c0,.1.2.2.3.2,0,0,.1,0,.2,0,.2,0,.2-.3.2-.5l-3.6-7.4h24.5c2.8,0,5.5.4,8.2,1.1h.3c0,0,2.3,4.1,2.3,4.1,0,.1.2.2.3.2h3v4.7ZM56.4,35.6l-1.9-3.4,2,1.1c.6.3,1.1.7,1.6,1.1l1.2,1.1h-2.9Z"/>
        </g>
      </g>
    </g>
  </svg>

</template>

<style lang="scss" scoped>
.cls-1 {
  fill: #252525;
  stroke: #252525;
  stroke-width: .3px;
}
</style>
